import React from 'react';
import styles from './Footer.module.css';

const Footer = ({}) => {
	return (
		<div className={styles.footer}>
 			Copyright © 2024 Aslanyan Tigran
 		</div>
	);
};



export default Footer;